import { Injectable } from '@angular/core';

import { AmplifyService } from 'aws-amplify-angular';
import Amplify, { AuthClass } from 'aws-amplify';
import { CognitoConfiguration} from '@athena/core/cognito.configuration';


@Injectable()
export class CognitoService {

	private cognitoAuth: AuthClass;

	constructor(private _amplifyService: AmplifyService) {
		// Configuracion del servicio AmplifyAWS para conectar con la pool de Athena-Users (AWS Cognito)
		Amplify.configure(CognitoConfiguration);
		this.cognitoAuth = this._amplifyService.auth();
	}

	/**
	 * Llamada realizada desde user.service para comprobar si hay un usuario activo y actualizar los token si procede
	 */
	getCognitoUser() {
		return this.cognitoAuth.currentAuthenticatedUser();
	}

	// Iniciar Sesion
	signInCognito(username: string, password: string) {
		return this.cognitoAuth.signIn(username, password);
	}

	// Finalizar Sesion
	signOutCognito() {
		return this.cognitoAuth.signOut();
	}

	// Obtener Promesa Credenciales
	getCredentials() {
		return new Promise((resolve, reject) => {
			this.cognitoAuth.currentCredentials()
				.then((credentials) => {
					console.log(credentials);
					resolve(this.cognitoAuth.essentialCredentials(credentials));
				})
				.catch((err) => {
					reject(401);
					console.log(err);
				});
		});
	}
}
