export class FileModel{
    //Constructor
    constructor(
        public tipo: string = "",
        public titulo: string = "",
        public comentarios: string = "",
        public version: string = "",
        public marcas: Array<string> = [],
        public fabricante: string = "",
        public categorias: Array<number> = [],
        public productos: Array<string> = [],
        public estado: string = "",
        public acceso: string = "",
        public paises: Array<string> = [],
        public idiomas: Array<string> = [],
        public fechaCreacion: string = "",
        public fechaPublicacion: string = "",
        public descripcionLarga: string = "",
        public sistemaOperativo: string = "",
        public archivo: Object = {"ruta": "", "tamanno": "", "fechaModificacion": "", "etag": ""},
        public IDFile: string = null,
    ){}

    toJson(){
        var objeto: any = {
            "tipo": this.tipo,
            "titulo": this.titulo,
            "comentarios": this.comentarios,
            "version": this.version,
            "marcas": this.marcas,
            "fabricante": this.fabricante,
            "categorias": this.categorias,
            "productos": this.productos,
            "estado": this.estado,
            "acceso": this.acceso,
            "paises": this.paises,
            "idiomas": this.idiomas,
            "fechaCreacion": this.fechaCreacion,
            "fechaPublicacion": this.fechaPublicacion,
            "descripcionLarga": this.descripcionLarga,
            "archivo": this.archivo
        }
        if(this.tipo=="software"){
            objeto.sistemaOperativo = this.sistemaOperativo
        }
        if(this.IDFile){
            return {
                "id": this.IDFile,
                "tipo": this.tipo,
                "file": objeto
            }
        }
        else{
            return objeto
        }
        
    }

    set(file, id=null){
        this.tipo = file.tipo;
        this.titulo = file.titulo;
        this.comentarios = file.comentarios;
        this.version = file.version;
        this.marcas = file.marcas;
        this.fabricante = file.fabricante;
        this.categorias = file.categorias;
        this.productos = file.productos;
        this.estado = file.estado;
        this.acceso = file.acceso;
        this.paises = file.paises;
        this.idiomas = file.idiomas;
        this.fechaCreacion = file.fechaCreacion;
        this.fechaPublicacion = file.fechaPublicacion;
        this.descripcionLarga = file.descripcionLarga;
        this.archivo = file.archivo;
        if(file.tipo="software"){
            this.sistemaOperativo=file.sistemaOperativo;
        }
        if(!id) this.IDFile = null;
        else this.IDFile = id;
    }
}