import { Component, OnInit } from '@angular/core';
import {DataRepositoryService} from '@athena/core/services/data-repository.service';

import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { UserService } from '@athena/core/services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  // Usuario guardado
  isLoggedIn$: Observable<boolean>;

  // Atributo para controlar el estado de la vista

  constructor(
    private _userService: UserService,
    private dataRepository: DataRepositoryService,
    private router: Router) {
   }

  ngOnInit() {
   // this.boton = this._userService.userActive ? true : false;
  this.isLoggedIn$ = this._userService.isLoggedIn;
  }

  onLogout() {
    this._userService.signOut()
    .then(()=>{
      this.router.navigate(['/login']);
    })
    .catch(()=>{
      this.router.navigate(['/login']);
    })
  }

  refreshLocalStorage() {
      this.dataRepository.refreshLocalStorage();
  }
}
