import { UserService } from '@athena/core/services/user.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class EditAuthGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.userService.getUserObject()
                .then((result) => {
                    if (result) {
                        if (this.userService.isSatUser()) {
                            console.log('Edit prevented from core/guard');
                            this.router.navigate(['/athena']);
                            resolve(false);
                        }
                        resolve(true);
                    } else {
                       this.router.navigate(['/login']);
                       resolve(false);
                    }
                });
       });
/*         return this.userService.isLoggedIn.pipe(
           take(1),
           map((isLoggedIn: boolean) => {
               if (!isLoggedIn) {
                   this.router.navigate(['/login']);
                   return false;
               }
               return true;
           })
       ); */
   
        /*
        if (this.userService.isSatUser()) {
            console.log('Edit prevented from core/guard');
            this.router.navigate(['/athena']);
            return false;
        }
        return true;*/
    }
}
