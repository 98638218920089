import {Injectable} from '@angular/core';

@Injectable()

export class IconService {
    // Funciones iconos
    iconImage(name): string {
        if (name !== '') {
            const ruta = '/assets/icons/';
            return ruta + name + '.png';
        }
    }

    iconImageWhite(name): string {
        if (name !== '') {
            const ruta = '/assets/icons/white_';
            return ruta + name + '.png';
        }
    }

    iconSO(name): string {
        if (name !== '') {
            const ruta = '/assets/icons/os/';
            return ruta + name + '.png';
        }
    }

    iconBackground(name): string {
        switch (name) {
            case 'firmware': {
                return '#26a9e0';
            }
            case 'manual': {
                return '#5ebb5e';
            }
            case 'guia': {
                return '#e8c560';
            }
            case 'folleto': {
                return '#f59cad';
            }
            case 'software': {
                return '#d9b0d3';
            }
            case 'certificado': {
                return '#93d2c0';
            }
            case 'ficha fabricante': {
                return '#dcaf81';
            }
        }
    }
}
