import { Injectable, ElementRef } from '@angular/core';
import * as sparkMD5 from 'spark-md5';
@Injectable({
  providedIn: 'root'
})
export class HashService {

  //Atributos
  private blobSlice: any;                       //Con este objeto recogemos pequennos fragmentos del archivo
  private chunkSize: number = 2097152           //Tamanno de los fragmentos
  private currentChunk: number                  //Mantiene el fragmento que se esta calculando el hash
  private spark: any                            //Objeto para calcular el hash de los objetos
  private file:any                              //Archivo a calcular el hash
  private chunks:any                            //Numero de fragmentos
  private fileReader: any                       //Lectura de los archivos


  constructor() {
  }

  private loadNext() {
    var start = this.currentChunk * this.chunkSize,
        end = ((start + this.chunkSize) >= this.file.size) ? this.file.size : start + this.chunkSize;

    this.fileReader.readAsArrayBuffer(this.blobSlice.call(this.file, start, end));
}

  getHash(inputVariable: ElementRef){

    return new Promise((resolve,reject)=>{
      var element = inputVariable.nativeElement;
      this.file = element.files[0];
      this.blobSlice = File.prototype.slice;
      this.chunks = Math.ceil(this.file.size / this.chunkSize);
      this.currentChunk = 0;
      this.spark = new sparkMD5.ArrayBuffer();
      this.fileReader = new FileReader();
      this.fileReader.onload = ((e: any) => {
        this.spark.append(e.target.result);                   
        this.currentChunk++;

        if (this.currentChunk < this.chunks) {
            this.loadNext();
        } else {
            var hash = this.spark.end()
            resolve(hash)
        }
      });

      this.fileReader.onerror = (()=>{
        reject(null)
      });

      this.loadNext();
    })
  }

    

}
