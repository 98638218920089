import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { UserService } from '../services/user.service';


@Injectable()
export class AuthGuard implements CanActivate {

constructor(private userService: UserService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
         return new Promise((resolve, reject) => {
             this.userService.getUserObject()
                 .then((result) => {
                     if (result) {
                         resolve(true);
                     } else {
                        this.router.navigate(['/login']);
                        resolve(false);
                     }
                 });
        });
/*         return this.userService.isLoggedIn.pipe(
            take(1),
            map((isLoggedIn: boolean) => {
                if (!isLoggedIn) {
                    this.router.navigate(['/login']);
                    return false;
                }
                return true;
            })
        ); */
    }
}
