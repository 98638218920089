// Elementos de las librerías de Angular
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

// Librerías de terceros
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';

// Elementos del módulo
import { AuthGuard, LoginGuard, EditAuthGuard, throwIfAlreadyLoaded} from '@athena/core';
import { AthenaService, CognitoService, DataRepositoryService, IconService, PeticionesService, S3Service, UserService, HashService} from '@athena/core';
import { MenuComponent } from '@athena/core';

// Elementos de otros módulos nuestros
import { SharedModule } from '@athena/shared';


@NgModule({
  imports: [
    AmplifyAngularModule,
    SharedModule,
    RouterModule
  ],
  providers: [
    AthenaService,
    CognitoService,
    UserService,
    S3Service,
    AmplifyService,
    PeticionesService,
    IconService,
    DataRepositoryService,
    AuthGuard,
    LoginGuard,
    EditAuthGuard,
    HashService
  ],
  declarations: [MenuComponent],
  exports: [MenuComponent]
})
export class CoreModule {
  // @SkipSelf le dice al DI que busque una instancia del módulo directamente en el inyector padre
  // @Optional le dice al DI que devuelva null si no se encuentra ninguna dependencia
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
