// Importar modulos del router de angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginGuard } from './core/guards/login.guard';
import { EditAuthGuard } from './core/guards/edit-auth.guard';

// Importar componentes
// import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
	{
		path: 'login',
		loadChildren: '@athena/login/login.module#LoginModule',
		canActivate: [LoginGuard]
	},
	{
		path: 'athena',
		loadChildren: '@athena/file/file.module#FileModule',
		canActivate: [AuthGuard]
	},
	{
		path: '**',
		redirectTo: 'athena'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {enableTracing: false, useHash: true})],
	exports: [RouterModule]
})
export class AppRoutingModule {}

