import { UserService } from '@athena/core/services/user.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { resolve6 } from 'dns';


@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.userService.getUserObject()
                .then((result) => {
                    if (result) {
                        this.router.navigate(['/athena'])
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
       });
/*         return this.userService.isLoggedIn.pipe(
           take(1),
           map((isLoggedIn: boolean) => {
               if (!isLoggedIn) {
                   this.router.navigate(['/login']);
                   return false;
               }
               return true;
           })
       ); */
   }
}
