import { Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class PeticionesService {
    private url: string;
    // private products: Products[];
    constructor(
        public _http: HttpClient
    ) {
        this.url = 'https://api.visiotechsecurity.com';

    }

    getProducts():Observable<any>{
        return this._http.get(this.url + '/es/api/athena/products');
    }
    getFabricantes():Observable<any>{
        return this._http.get("https://api.visiotechsecurity.com/es/api/athena/manufacturers");
    }

    getMarcas():Observable<any>{
        return this._http.get("https://api.visiotechsecurity.com/es/api/athena/brands");
    }
    getCategorias():Observable<any>{
        return this._http.get("https://api.visiotechsecurity.com/es/api/athena/categories");
    }

}