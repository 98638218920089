import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UserService } from './user.service';

@Injectable()
export class AthenaService {

    private endPointAWS = 'https://e8b8sbzf5m.execute-api.eu-west-1.amazonaws.com/prod';
    private endPointElasticSearch = 'https://search-visiotech-athena-jccxf5cxjwlqo775ko6tcpi5m4.eu-west-1.es.amazonaws.com/fichero/_search';
    
    //private usuario_guardado; //Cuando actualizamos los campos de los componentes

    constructor(
        private _userService: UserService,
        private _http: HttpClient) {
    }

    /* Se llama cuando se realiza una búsqueda. Devuelve todos los ficheros a
    los que esté asociada la referencia introducida o su/s categoría/s  */
    getFileJSONByProduct(idProduct, categorias) {
        var query = {
            "size": 2000,
            "query": {
                "bool": {
                    "should": [
                        {"match_phrase": {
                            "productos": idProduct
                        }},
                        {"terms": {
                            "categorias": categorias
                        }}

                    ]
                }
            }
        };
        return (this._http.post(this.endPointElasticSearch,query))
    }

    getFileByAnyField(string){
        /*var query = {
            "size": 30,
            "filter": {
              "multi_match" : {
                "query": string,
                "type": "best_fields",
                "fields": [ "tipo", "titulo","comentarios","estado", "marcas", "version"]
              }
            }
        };*/

        var query = {
            "size": 500,
            "query": {
                "bool": {
                "should": [
                    {"match_phrase": {"titulo":  string}},
                    {"match_phrase": {"estado":  string}},
                    {"match_phrase": {"marcas":  string}},
                    {"match_phrase": {"version":  string}}
                    ]
                }
            }
              
        }

        return(this._http.post(this.endPointElasticSearch, query))
    }

     /* Se llama cuando se realiza una búsqueda. Devuelve todos los ficheros
    que tenga el estado de borrador  */
    getBorradorFile() {
        var query = {
            "query": {
                "bool": {
                    "must": [
                        {"match_phrase": {"estado":"borrador"}}
                    ]
                }
            },
            "size": 1000
        };
        return (this._http.post(this.endPointElasticSearch,query))
    }

    /* Obtiene la información de un fichero*/
    getFileJSONById(ID) {
        var query = {
            "query": {
                "bool": {
                    "must": [
                        {"match_phrase": {"_id":ID}}
                    ]
                }
            }
        }
        return (this._http.post(this.endPointElasticSearch,query))
    }

    //Ver si hay ficheros que tengan un archivo asociado con ese hash
    getEtag(etag){
        var query = {
            "query":{
                "bool":{
                    "must": [
                        {"match_phrase": {"archivo.etag":etag}}
                    ]
                }
            }
        }
        return (this._http.post(this.endPointElasticSearch,query))
    }

    /* Crea un fichero en ElasticSearch */
    uploadFileJSON(file) {
        return new Promise((resolve, reject) => {
            this._userService.getUserObject()
                .then((credentials: any) => {
                    const httpOptions = {
                        headers: new HttpHeaders({
                            'Authorization': credentials.accessToken.jwtToken,
                        })
                    };
                    resolve(this._http.post(this.endPointAWS + '/fichero/alta', file, httpOptions)
                        .pipe(
                            catchError(this.handleError)
                        ));

                })
                .catch((err) => {
                    reject(401);
                })
        })

    }

    /* Modifica un fichero en ElasticSearch */
    updateFileJSON(file) {
        
        return new Promise((resolve, reject) => {
            this._userService.getUserObject()
                .then((credentials: any) => {
                    console.log(credentials)
                    let httpOptions = {
                        headers: new HttpHeaders({
                            'Authorization': credentials.accessToken.jwtToken,
                        })
                    };
                    resolve(this._http.put(this.endPointAWS + '/fichero/actualizar', file, httpOptions)
                        .pipe(
                            catchError(this.handleError)
                        ));
                })
                .catch((err) => {
                    reject(401)
                })
        })
    }

    /* Elimina un fichero en ElasticSearch */
    deleteFileJSON(file) {
        return new Promise((resolve, reject) => {
            this._userService.getUserObject()
                .then((credentials: any) => {
                    let httpOptions = {
                        headers: new HttpHeaders({
                            'Authorization': credentials.accessToken.jwtToken,
                            'fileid': file.id,
                            'filetipo': file.tipo
                        })
                    };
                    resolve(this._http.delete(this.endPointAWS + '/fichero/baja', httpOptions)
                        .pipe(
                            catchError(this.handleError)
                        )
                    );
                })
                .catch((err) => {
                    reject(401);
                })
        });
    }

    private handleError(error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(error.error.errorMessage.split(':')[1]);
    }
}
