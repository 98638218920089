import { Injectable } from '@angular/core';
import { PeticionesService} from "@athena/core/services/peticiones.service";
import {map, startWith} from "rxjs/operators";
import {Categoria} from "@athena/shared/models/categoria.model";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class DataRepositoryService {
    private marcas: Array<string>;
    private fabricantes: Array<string>;
    private categorias: Array<Categoria>;
    private productos: Array<any>;

    //Variables de control de carga
    public cargandoMarcas= false;
    public cargandoFabricantes= false;
    public cargandoProductos= false;
    public cargandoCategorias= false;

    //Observables de datos
    public marcas$: BehaviorSubject<Array<string>>;
    public fabricantes$: BehaviorSubject<Array<string>>;
    public productos$: BehaviorSubject<Array<any>>;
    public categorias$: BehaviorSubject<Array<Categoria>>;

    constructor(
        private apiVisiotech: PeticionesService
    ) {
        this.marcas$ = new BehaviorSubject<Array<string>>(null);
        this.fabricantes$ = new BehaviorSubject<Array<string>>(null);
        this.productos$ = new BehaviorSubject<Array<any>>(null);
        this.categorias$ = new BehaviorSubject<Array<Categoria>>(null);

        window.addEventListener('storage', (event$) => {
            switch (event$.key) {
                case 'marcas': {
                    this.getMarcas();
                    break;
                }
                case 'fabricantes': {
                    this.getFabricantes();
                    break;
                }
                case 'productos': {
                    this.getProductos();
                    break;
                }
                case 'categorias': {
                    this.getCategorias();
                    break;
                }
            }
        });
    }



    getMarcas() {
        if (!localStorage.getItem('marcas')) {

            this.getMarcasApi();

        } else {
            /*IF PARA ACTUALIZAR MAYUSCULAS, PROVISIONAL!!!*/
            if(JSON.parse(localStorage.getItem('marcas'))[0].charAt(1)=='S'){ //El primer elemento de marcas es 3S, y debe ser 3s
                console.log('Actualizamos mayusculas marcas')
                this.getMarcasApi();
            } 
            else{
                //ESTO SI SE DEBE CONSERVAR
                console.log("Cogemos las marcas de local storage");
                this.marcas$.next(JSON.parse(localStorage.getItem('marcas')));
            }
        }
    }
    getMarcasApi(){
        console.log("Cogemos las marcas de la api");
        this.cargandoMarcas= true;
        this.apiVisiotech.getMarcas().subscribe(
            result => {
                this.cargandoMarcas= false;
                this.marcas$.next(result);
                localStorage.setItem('marcas',JSON.stringify(result));
            },
            error => {
                debugger;
            })
    }

    getFabricantes(){
        if (!localStorage.getItem('fabricantes')) {

            this.getFabricantesApi();

        } else {
            /*IF PARA ACTUALIZAR MAYUSCULAS, PROVISIONAL!!!*/
            if(JSON.parse(localStorage.getItem('fabricantes'))[1].charAt(0)=='A'){ //El segundo elemento de marcas es Ajax, y debe ser ajax
                console.log('Actualizamos mayusculas fabricantes')
                this.getFabricantesApi();
            } 
            else{
                //ESTO SI SE DEBE CONSERVAR
                console.log("Cogemos los fabricantes de local storage");
                this.fabricantes$.next(JSON.parse(localStorage.getItem('fabricantes')));
            }
        }
    }
    getFabricantesApi(){
        console.log("Cogemos los fabricantes de la api");
        this.cargandoFabricantes= true;
        this.apiVisiotech.getFabricantes().subscribe(
            result => {
                this.cargandoFabricantes= false;
                this.fabricantes$.next(result);
                localStorage.setItem('fabricantes',JSON.stringify(result));
            },
            error => {
                debugger;
            })
    }

    getCategorias(){
        if (!localStorage.getItem('categorias')) {

            this.getCategoriasApi();

        } else {
            console.log("Cogemos los categorias de local storage");
            this.categorias$.next(JSON.parse(localStorage.getItem('categorias')));
        }
    }
    getCategoriasApi(){
        console.log("Cogemos los categorias de la api");
        this.cargandoCategorias= true;
        this.apiVisiotech.getCategorias().subscribe(
            result => {
                this.cargandoCategorias= false;
                this.categorias$.next(result);
                localStorage.setItem('categorias',JSON.stringify(result));
            },
            error => {
                debugger;
            })
    }

    getProductos(){
        if (!localStorage.getItem('productos')) {
            this.getProductosApi();
        } else {
            console.log("Cogemos los productos de local storage");
            this.productos$.next(JSON.parse(localStorage.getItem('productos')));
        }
    }
    getProductosApi(){
        console.log("Cogemos los productos de la api");
        this.cargandoProductos= true;
        this.apiVisiotech.getProducts().subscribe(
            result => {
                this.cargandoProductos= false;
                this.productos$.next(result);
                localStorage.setItem('productos',JSON.stringify(result));
            },
            error => {
                console.error(<any>error);
            }
        )
    }

    refreshLocalStorage(){
        this.getProductosApi();
        this.getCategoriasApi();
        this.getMarcasApi();
        this.getFabricantesApi();
    }


    getMarcas_old() {
        return new Promise((resolve, reject) => {
            if (!localStorage.getItem('marcas')) {
                console.log("Cogemos las marcas de la api");
                this.apiVisiotech.getMarcas().subscribe(
                    result => {
                        localStorage.setItem('marcas',JSON.stringify(result));
                        resolve (result);
                    },
                    error => {
                        reject(<any>error);
                        debugger;
                    })
            } else {
                console.log("Cogemos las marcas de local storage");
                resolve(JSON.parse(localStorage.getItem('marcas')));
            }
        })
    }


    getFabricantes_old() {
        return new Promise((resolve, reject) => {
            if (!localStorage.getItem('fabricantes')) {
                console.log("Cogemos los fabricantes de la api");
                this.apiVisiotech.getFabricantes().subscribe(
                    result => {
                        localStorage.setItem('fabricantes',JSON.stringify(result));
                        resolve (result);
                    },
                    error => {
                        reject(<any>error);
                        debugger;
                    })
            } else {
                console.log("Cogemos los fabricantes del local storage");
                resolve(JSON.parse(localStorage.getItem('fabricantes')));
            }
        })
    }

    getCategorias_old() {
        return new Promise((resolve, reject) => {
            if (!localStorage.getItem('categorias')) {
                console.log("Cogemos las categorías de la api");
                this.apiVisiotech.getCategorias().subscribe(
                    result => {
                        localStorage.setItem('categorias',JSON.stringify(result));
                        resolve (result);
                    },
                    error => {
                        reject(<any>error);
                        debugger;
                    })
            } else {
                console.log("Cogemos las categorías de local storage");
                resolve(JSON.parse(localStorage.getItem('categorias')));
            }
        })
    }
    getProductos_old() {
        return new Promise((resolve, reject) => {
            if (!localStorage.getItem('productos')) {
                console.log("Cogemos los productos de la api");
                this.apiVisiotech.getProducts().subscribe(
                    result => {
                        localStorage.setItem('productos',JSON.stringify(result));
                        resolve (result);
                    },
                    error => {
                        reject(<any>error);
                        debugger;
                    })
            } else {
                console.log("Cogemos los productos de local storage");
                resolve(JSON.parse(localStorage.getItem('productos')));
            }
        })
    }


    /*Transforma el id de las categorías seleccionadas de un fichero al nombre correspondiente
    return = Array Nombre categorías relacionadas de un fichero
    categoriasId = Array Id categorías relacionadas de un fichero
    this.categorias= Array con la relación de nombres e ids*/
    apiCategoriesToLocal(categoriasId){
        let categorias = JSON.parse(localStorage.getItem('categorias'));
        return categorias.filter(option=> categoriasId.indexOf(+option.id) >=0);
    }

    categoriesIdToName(categoriasId){
        let categorias = JSON.parse(localStorage.getItem('categorias'));
        return categorias.filter(option=> categoriasId.indexOf(+option.id) >=0).map(value=>value.name);
    }

}