import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import { CognitoService } from './cognito.service';

import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable()
export class UserService {

    // Atributos
    public userActive: Object = null;
    private loggedIn$ = new BehaviorSubject<boolean>(false); // Observable para informar al resto de vistas de la aplicacion

    get isLoggedIn() {
        return this.loggedIn$.asObservable();
    }

    // Constructor
    constructor(private _authenticationService: CognitoService, private _router: Router) {

        // Annadir observador en LocalStorage para cambios de sesion en otras pestannas
        window.addEventListener('storage', (event) => {
            console.log('%c Evento cambiado: ' + event.key, 'color: orange; font-weight: bold;');
            if (event.key === 'userActive') {
                console.log('Dentro de la comprobación de UserActive en storage');
                const oldUser = this.userActive;
                this.userActive = JSON.parse(localStorage.getItem('userActive'));
                if (!this.userActive) {
                    this.loggedIn$.next(false);
                    this._router.navigate(['/login']);
                }
                if (!oldUser && this.userActive) {
                    this.loggedIn$.next(true);
                    this._router.navigate(['/athena']);
                }
            } else {
                console.log('Fuera de la comprobación de UserActive en storage');
            }
        });
    }


    /**
     * @returns promesa que devuelve el objeto del usuario activo (si lo hay)
     */
    public getUserObject() {
        return new Promise((resolve, reject) => {

            /* Si no hay usuario en la variable del servicio, lo buscamos en localStorage */
            if (!this.userActive) {
                const userFromLocalStorage = localStorage.getItem('userActive');
                /* Si no hay usuario en LocalStorage, devolvemos false para que se redirija al login */
                if (userFromLocalStorage === null) {
                    console.log('No hay usuario en localStorage ni en userService');
                    //this._router.navigate(['/login']);
                    resolve(false);
                    return;
                }
                /* Si hay usuario en LocalStorage, lo asignamos a la variable del servicio */
                console.log('Se asigna a userActive el contenido de LocalStorage');
                this.userActive = JSON.parse(userFromLocalStorage);
            }

            this.updateUserTokens()
                .then(() => {
                    this.loggedIn$.next(true);
                    resolve(this.userActive);
                })
                .catch(() => {
                    //this._router.navigate(['/login']);
                    resolve(false);
                });
        });
    }

    /**
     * Comprueba la validez del usuario activo en función del token de expiración. En caso de no ser válido, actualiza
     * el token del usuario.
     */
    private updateUserTokens() {
        return new Promise((resolve, reject) => {
            const mydate = Date.now().valueOf() / 1000;    // Obtener la hora actual de la peticion
            const token = jwt_decode(this.userActive['accessToken']['jwtToken']); // Obtener token de acceso
            const date = token['exp'];  // Obtener la fecha de expiracion del token de acceso

            if (date > mydate) { // El token de acceso sigue siendo valido, se devuelve las credenciales ya guardadas
                resolve(); // Se devuelven las credenciales
            } else {
                this._authenticationService.getCognitoUser() // El token de acceso ha caducado, se llama a Cognito para actualizarlo
                    .then((userInfo) => {
                        console.log('Token actualizado');
                        localStorage.setItem('userActive', JSON.stringify(userInfo['signInUserSession']));
                        this.userActive = userInfo['signInUserSession'];      // Cognito ha actualizado el token de acceso correctamente
                        resolve();
                    })
                    .catch((err) => {
                        console.log('Error en getUserObject: ' + (err));
                        this.userActive=null;
                        localStorage.removeItem('userActive');
                        // Fallo al actualizar credenciales, se devuelve el error al servicio.
                        reject(err);
                    });
            }
        });
    }


    public signIn(username: string, password: string) {
        return new Promise((resolve, reject) => {
            this._authenticationService.signInCognito(username, password)
                .then(user => {
                    let session = user['signInUserSession']
                    this.userActive = session;
                    localStorage.setItem('userActive', JSON.stringify(session));
                    this.loggedIn$.next(true); // Actualizar informacion a user.service
                    resolve();
                })
                .catch(err => {
                    console.log("Error en login")
                    localStorage.removeItem('userActive');
                    this.loggedIn$.next(false); // Actualizar informacion a user.service
                    reject(err);
                });
        });
    }

    public signOut() {
        return new Promise((resolve, reject) => {
            this._authenticationService.signOutCognito()
                .then(data => {
                    this.loggedIn$.next(false); // Actualizar informacion a user.service
                    this.userActive = null;
                    localStorage.removeItem('userActive');
                    //this._router.navigate(['/login']);
                    resolve();
                })
                .catch(err => {
                    this.loggedIn$.next(false); // Actualizar informacion a user.service
                    this.userActive = null;
                    localStorage.removeItem('userActive');
                    console.log(`Error en sign out: ${err}`);
                    //this._router.navigate(['/login']);
                    reject();
                });
        });
    }

    // Promesa para devolver las credenciales para el servicio de S3
    public getCredentials() {
        return this._authenticationService.getCredentials();
    }

    /**
     * Comprueba si el usuario activo pertenece al grupo de sat (para prevenir el acceso a la edición de ficheros)
     */
    public isSatUser(): boolean {
        return localStorage['CognitoIdentityServiceProvider.mv2hevhp1dvnneavm69umjbss.LastAuthUser']=== 'sat';
    }
}
