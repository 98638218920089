import { Injectable } from '@angular/core';

import { UserService } from '@athena/core/services/user.service';

import * as AWS_S3 from 'aws-sdk/clients/s3';


@Injectable()
export class S3Service{

    private _s3Service: AWS_S3 = null;
    constructor(private _userService: UserService) {
    }

    createAutentificatedClient() {
        return new Promise((resolve, reject) => {
            this._userService.getCredentials()
                .then((credentials2: any) => {
                    this._s3Service = new AWS_S3({
                        credentials: credentials2,
                        region: 'eu-west-1'
                    });
                    console.log("Credenciales:")
                    console.log(credentials2);
                    resolve();
                    // El usuario tiene una sesion inciada y se permite realizar la operacion
                })
                .catch((err) => {
                    this._s3Service = null;
                    reject(401);
                    // El usuario no tiene sesion iniciada o expirada y debe autentificarse de nuevo
                })
        })
    }

    /* Sube un archivo adjunto al s3 */
    uploadFileS3(file: File, id:string){
        return new Promise ((resolve, reject) => {
            this.createAutentificatedClient() // JAVI: ¿Esto es necesario aquí?
                .then(() => {
                    console.log(file.name);
                    //Operacion aceptada, se realiza la subida
                    this._s3Service.putObject({
                        Bucket: 'athena-visiotech',
                        Key: id+'/'+file.name,
                        Body: file
                    }, (err, data) => {
                        if(err) {console.log(err); reject(500);}
                        else {console.log(data);resolve(data)};
                    })
                })
                //Operacion rechazada, no se realiza la subida
                .catch(err => {
                    reject(err);
                });
        });
    }

    /* Elimina un archivo adjunto del S3 */
    deleteFileS3(id){
        return new Promise ((resolve, reject) => {
            this.getListObjects(id)
                .then((data: any) => {
                    if(data.length != 1){
                        this._s3Service.deleteObject({
                        //Operacion aceptada, se realiza la eliminacion
                            Bucket: 'athena-visiotech',
                            Key: data[1].Key
                    }, (err, data) => {
                        if(err) {console.log(err); reject(500);}
                        else {console.log(data);resolve(data)};
                        }) 
                        
                    }
                    else{
                        resolve('No hay archivos que eliminar en este fichero')
                    }
                })
                .catch(err => {
                    reject(err);
                })
            });
    }

    /* Obtiene todos los archivos adjuntos del s3 */
    getListObjects(id:string){
        return new Promise ((resolve, reject) => {
                this.createAutentificatedClient()
                .then(() => {
                    this._s3Service.listObjects({
                        Bucket: 'athena-visiotech',
                        Prefix: id+'/'
                    }, (err,data) => {
                        if(err) {console.log(err); reject('No encontrado');}
                        else {console.log(data); resolve( data.Contents);}
                    })
                })
                .catch(err => {
                    reject(err);
                })
            })
    }

    /* */
    updateFileS3(id,file){
        return new Promise((resolve,reject)=> {
            this.deleteFileS3(id)
                .then((data) => {
                    if(file){
                        this.uploadFileS3(file,id)
                            .then((data) => {
                                resolve(data)
                            })
                            .catch((err) => {
                                console.log(err);
                                reject(err);
                            })
                    }
                    else{
                        resolve(data)
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                })
            });
    }
}
